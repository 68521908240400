/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.booking-page {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
}
.booking-page > h1 {
  background-color: #000e68;
  color: white;
  display: flex;
  margin-bottom: 0.8rem;
  padding: 0.7rem;
  border-radius: 5px;
}

.step-content {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background: #f9f9f9;
}
.step-1 {
  flex-direction: column;
  display: flex;
  gap: 0.8rem;
}
.cabins {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.3rem;
}
.cabins div > label {
  margin-left: 0.4rem;
}
.cabins div {
  margin-bottom: 10px;
}

/* Step 2: Transportation Options */
.transportation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
}

.transportation-options {
  margin-top: 15px;
}

.transport-option {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;
  background: #fff;
}

.transport-option.active {
  background: #eaf7ff;
  border-color: #00aaff;
}

.transport-option .dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

.transport-option .dropdown-toggle label {
  margin-left: 10px;
  font-weight: bold;
}

.transport-option input[type="checkbox"] {
  margin-right: 10px;
}

.dropdown-content {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #f7f7f7;
}

.dropdown-content h3 {
  margin-bottom: 10px;
}

.class-option {
  margin-bottom: 8px;
}

.class-option input[type="radio"] {
  margin-right: 10px;
}

/* Traveler Information */
.traveler-info {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}

.traveler-info select,
.traveler-info input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.add-traveler {
  background: #00aaff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.add-traveler:hover {
  background: #008ecc;
}

/* Navigation Buttons */
.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.navigation-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.back-button {
  background: #ccc;
  color: #333;
}

.back-button:disabled {
  background: #eee;
  cursor: not-allowed;
}

.next-button {
  background: #00aaff;
  color: white;
}

.next-button:hover {
  background: #008ecc;
}
.step h2 {
  border-bottom: 1px dashed black;
  padding-bottom: 0.5rem;
}
.step p {
  padding: 0.4rem;
  background-color: #000e68;
  color: white;
  display: flex;
  margin-top: 0.4rem;
}
.step p strong {
  color: white;
  padding-right: 0.3rem;
}
/* Payment Section */
.payment-button {
  background: #00aaff;
  color: white;
  border: none;
  padding: 15px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 0.8rem;
}
.step li {
  padding: 0.5rem 0;
}
.step li:last-child {
  border-bottom: 1px dashed black;
  padding-bottom: 0.5rem;
}
.payment-button:hover {
  background: #008ecc;
}
.payment-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  text-align: center;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.traveler-toggle {
  margin-top: 0.3rem;
  display: flex;
  gap: 0.4rem;
      align-items: center;
}
@media (max-width: 545px) {
  .sticky-layout {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
  .traveler-toggle {
    flex-direction: column;
  }
  .step > h2 {
    margin-bottom: 0.3rem;
  }
}
