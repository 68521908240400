/* Existing FAQ styles */
.faq-container {
  margin: 0;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.faq-container > h2 {
  padding: 20px;
}

.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 10px 20px;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-answer {
  margin-top: 5px;
  font-size: 0.95em;
  color: #555;
  line-height: 1.6;
}

.faq-banner {
  background-image: url("../../images/pexels-media-29530548[1].jpeg");
  background-size: cover;
  background-position: center;
  padding: 40px;
  color: #fff;
  text-align: left;
  margin-bottom: 20px;
  max-width: 1200px;
  height: 250px;
}

.banner-content h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.banner-features {
  list-style: none;
  padding: 0;
  line-height: 1.6;
}
.banner-heading {
  display: flex;
  align-items: center;
}
.banner-heading > img {
  height: 5rem;
  width: auto;
  object-fit: contain;
}
.banner-features li {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 8px;
}

.icon-check {
  color: #00ff99;
  margin-right: 10px;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .faq-banner {
    padding: 20px;
  }

  .banner-heading h1 {
    font-size: 1rem;
  }
  .banner-features {
    line-height: 1rem;
  }
}
