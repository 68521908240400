/* PaymentPage.css */
.payment-page {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
}

.payment-page h1 {
  font-size: 24px;
  margin-bottom: 20px;
}
.payment-page h2 {
  margin-bottom: 0.8rem;
}
.payment-options {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.payment-option {
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  transition: background-color 0.3s, transform 0.3s;
  width: 100px;
}

.payment-option:hover {
  background-color: #f0f0f0;
  transform: scale(1.1);
}

.payment-option p {
  margin-top: 8px;
  font-size: 14px;
}

.payment-details {
  margin-top: 20px;
  border-top: 1px solid #ccc;
  padding-top: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
}

.payment-details p {
  margin-bottom: 10px;
  font-size: 16px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
.payment-details > ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
}
.payment-details ul > li {
  list-style-type: none;
  font-weight: 600;
  font-size: 0.9rem;
  cursor: pointer;
  margin-bottom: 10px;
}
.form-container {
  text-align: left;
  margin: 20px auto;
  max-width: 300px;
}

.form-container input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-container button {
  width: 100%;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.form-container button:hover {
  background-color: #0056b3;
}
button.close-button {
  display: flex;
  align-items: center;
  padding: 1.2rem;
  width: auto;
  top: 270px;
  left: 50px;
  right: auto;
}

.spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #474bff);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
  animation: spinner-rotation 1s infinite linear;
  position: relative;
  z-index: 10; /* Ensure it's on top */
}

@keyframes spinner-rotation {
  to {
    transform: rotate(1turn);
  }
}


.payment-status {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex
;
    flex-direction: column;
    align-items: center;
}

.payment-status h3 {
  color: #003087;
}

.payment-status p,
.payment-status ul {
  font-size: 16px;
  color: #333;
}

.payment-status button {
  background-color: #003087;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.payment-status button:hover {
  background-color: #00266d;
}

.payment-status ul {
  list-style-type: none;
  padding: 0;
}

.payment-status ul > li {
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 10px;
}
