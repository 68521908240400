.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.forgot-password-box {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.forgot-password-title {
  text-align: center;
  margin-bottom: 20px;
}

.forgot-password-form .form-group {
  margin-bottom: 15px;
}

.forgot-password-form input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.reset-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}

.reset-button:hover {
  background: #0056b3;
}

.success-message {
  color: green;
  margin-top: 10px;
  font-size: 0.9em;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: 0.9em;
}

.back-to-login {
  text-align: center;
  margin-top: 15px;
}
