.signup-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 20px;
  animation: backgroundSwitch 10s infinite alternate;
  background-size: cover;
  background-position: center;
  background-image: url("../../images/banner-tour-1.jpg");
}

@keyframes backgroundSwitch {
  0% {
    background-image: url("../../images/banner-tour-1.jpg");
  }
  50% {
    background-image: url("../../images/banner-tour-2.jpg");
  }
}

.signup-form {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  flex-direction: column;
    display: flex

}

.signup-form h2 {
  margin-bottom: 20px;
  text-align: center;
}

.signup-form input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

#acceptTerms{
width: auto;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;  
}

.name-fields {
  display: flex;
  gap: 10px;
}

.terms-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.terms-checkbox label {
  margin-left: 5px;
}

.signup-form button {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}

.signup-form button:hover {
  background: #0056b3;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: 0.9em;
}

.signup-form p {
  text-align: center;
  margin-top: 15px;
}

.password-input-wrapper {
  position: relative;
}

.password-input {
  width: 100%;
  padding: 10px 40px 10px 10px; 
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2em;
  color: #888;
  transition: color 0.3s ease;
}

.password-toggle-icon:hover {
  color: #007bff;
}
