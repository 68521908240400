.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff; /* Optional: background color during loading */
}

.loader img {
  width: 100px; /* Adjust size as needed */
}
.nav-image {
  background-image: url(../images/photo_2024-11-27_08-52-51.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  min-height: 20rem;
  width: 100%;
}
