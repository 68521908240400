body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
}

.profile-container{
    display: flex;  
    height: 100%;
}

.profile-page {
  max-width: 800px;
 margin: 10px 40px;
  border-radius: 8px;
  padding: 20px;
}

.profile-page h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.profile-section,
.password-section {
  margin-bottom: 30px;
}

.profile-section h3,
.password-section h3 {
  color: #12264a;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.profile-form label,
.password-form label {
  display: block;
  margin-bottom: 15px;
  font-size: 14px;
  color: #555;
}

.profile-form label input,
.password-form label input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
    margin-top: 0.5rem;
    box-sizing: border-box;
}

.name-input{
  text-transform: capitalize;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
}

button:hover {
  background-color: #45a049;
}

button svg {
  margin-right: 5px;
}

input:focus {
  outline: none;
  border-color: #4caf50;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
}

/* Icons Alignment */
label svg {
  margin-right: 8px;
  vertical-align: middle;
  color: #4caf50;
}

.password-input {
  flex: 1;
}

.toggle-password {
  position: absolute;
    right: 86px;
    cursor: pointer;
    top: 1005px;
}

/* crumbs */
.breadcrumb {
      margin: 1rem 3rem;
      padding: 0.5rem;
  font-size: 0.9rem;
  color: #12264a;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background: #fff;
  display: flex
;
    gap: 0.3rem;
    text-align: center;
    align-items: center;
}

.breadcrumb-link {
  color: #12264a;
  text-decoration: none;
  text-align: center;
    display: flex
;
    gap: 0.1rem;
}

.breadcrumb-link:hover {
  color: #0056b3;
  cursor: pointer;
}


/* side bar */
.profile-sidebar {
  background-color: #12264a;
  color: #fff;
  padding: 10px;
  text-align: center;
      display: flex
;
    flex-direction: column;
    gap: .8em;
    height: 100vh;
    max-width: 175px;
    position: fixed;
    align-items: center;

}
 
.main-content{
  display: flex
;
    flex-direction: column;
    height: 100%;
    margin-left: 10.5rem;
    /* padding: 1rem; */
    width: 100%;
}

.profile-avatar {
  position: relative;
}

.member-icon {
    margin-right: 0.3rem;
}

.avatar-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 3px solid #4caf50;
  object-fit: cover;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional */
  display: block; /* Ensure it's displayed as a block element */
}

.upload-avatar {
  display: inline-block;
  margin-top: 10px;
  cursor: pointer;
  color: #4caf50;
}

.upload-avatar input {
  display: none;
}

h3 {
  margin: 15px 0 5px;
   text-transform: capitalize;
}

.member-since {
  font-size: 14px;
  color: #bbb;
  margin-bottom: 20px;
}

.become-vendor {
  background-color: #ff9800;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.become-vendor:hover {
  background-color: #e68900;
}

.profile-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.profile-menu li {
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  display: flex
;
    align-items: center;
    margin-top: 0.2rem;
}

.profile-menu li:hover,
.profile-menu .active {
  background-color: #4caf50;
  color: #fff;
}

/* booking history */
/* General styling for booking history page */
.booking-history-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.booking-history-header {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.booking-history-tabs {
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.booking-history-tabs button {
  flex: 1;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.booking-history-tabs button.active {
  background-color: #4caf50;
  color: #fff;
}

.booking-history-tabs button:hover {
  background-color: #ddd;
}

.booking-history-list {
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  padding: 15px;
}

.booking-history-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f0f0f0;
}

.booking-history-item:last-child {
  border-bottom: none;
}

.booking-details {
  flex: 2;
  color: #333;
}

.booking-status {
  flex: 1;
  text-align: center;
  color: #fff;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 4px;
}

.booking-status.processing {
  background-color: #ff9800;
}

.booking-status.completed {
  background-color: #4caf50;
}

.booking-status.paid {
  background-color: #2196f3;
}

.no-bookings {
  text-align: center;
  color: #777;
  padding: 20px 0;
  font-size: 16px;
}


.tabs {
    display: flex
;
    gap: 0.8rem;
        flex-wrap: wrap;
    
}

.booking-history{
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 0.5rem;
}

.booking-tab-button {
  background-color: transparent;
  color: black;
  position: relative; /* Needed for ::after to position correctly */
  display: inline-block; /* Ensures pseudo-elements are rendered */
  border: none; /* Removes default button border */
  padding: 10px 15px;
  cursor: pointer;
}

.booking-tab-button.active::after {
  content: "";
  position: absolute;
  bottom: 0.4em;
  left: 0;
  width: 100%; 
  border-radius: 3px;
  height: 3px;
  /* background: #6c9711; */
  transition: all 0.4s ease-in-out;
}

.booking-tab-button::after {
  content: "";
  position: absolute;
  bottom: 0.4em;
  left: 0;
  width: 0; /* Initially hidden */
  border-radius: 3px;
  height: 3px;
  background: #6c9711;
  transition: all 0.4s ease-in-out;
}

.booking-tab-button:hover {
  background-color: transparent;
}

.booking-list {
    margin: 0;
    padding: 0.9rem;
}


/* Responsive Design */
@media (max-width: 600px) {
  .profile-page {
    margin: 20px;
    padding: 2px 25px;
  }
  button {
    font-size: 12px;
    padding: 8px 10px;
  }
  label input {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .main-content {
    margin-left: 2rem;
    overflow-y: scroll;
  }

  .profile-sidebar {
    max-width: 100%;
    align-items: center;
    height: 100svh;
    transition: width 200ms ease;
  }

 /* Hide elements initially */
.profile-avatar, .hide, .hide-text {
    display: none;
}

/* When hovering on profile-sidebar, show hidden elements */
.profile-sidebar:hover .profile-avatar,
.profile-sidebar:hover .hide,
.profile-sidebar:hover .hide-text {
    display: block;
}


  .profile-sidebar:hover {
    width: 13rem;
    transition: width 200ms ease;
    z-index: 12;
  }

}

.booking-history ul {
    margin-left: 2rem;
    display: flex
;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
}