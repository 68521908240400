.cruise-card {
  position: relative; 
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  text-align: left;
  margin: 10px;
  height: 100%; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cruise-cards-container {
  padding: 1.5rem;
  max-width: 100%;
  position: relative;
}

.cruise-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  position: relative;
}
.cruise-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 10px;
}

.cruise-details > h3 {
  position: absolute;
  top: 10px; 
  left: 10px;
  background: rgba(0, 0, 0, 0.6); 
  color: white;
  padding: 5px 10px;
  font-size: 1.2rem;
  border-radius: 5px;
}

.cruise-details > div{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  gap: 0.5rem;
}


.cruise-details >div  p {
  color: black;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
}

.cruise-details p:nth-of-type(1) {
  font-weight: 700;
}

.cruise-price-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infoo-button {
  background: none;
  border: none;
  color: #007bff;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s;
}

.infoo-button:hover {
  color: #0056b3;
}

.carousel-container {
  width: 100%;
  max-width: 1200px; /* Center and restrict width */
  margin: auto;
}

.carousel-item-padding-0-px {
  padding: 0; /* Remove unnecessary padding */
}

.ship-icon{
  margin-right: 3px;
  font-size: 1rem;
  width: 1rem;
  display: inline-flex;
}

/* Responsive adjustments for smaller devices */
@media (max-width: 768px) {
  .cruise-card {
    width: 280px;
    margin: 0 auto 10px auto;
    height: 100%;
  }

  /* .cruise-image {
    height: 120px;
  } */
}

@media (min-width: 768px) and (max-width: 1024px) {
  .cruise-card {
    max-width: 300px; /* Adjust width for tablets */
    margin: 10px auto;
    height: 100%;
  }
}
