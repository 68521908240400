
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.login-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  animation: backgroundSwitch 10s infinite alternate;
  background-size: cover;
  background-position: center;
   background-image: url("../../images/banner-tour-1.jpg");
}

@keyframes backgroundSwitch {
  0% {
    background-image: url("../../images/banner-tour-1.jpg");
  }
  50% {
    background-image: url("../../images/banner-tour-2.jpg");
  }
}


.login-box {
  background: #fff;
  padding: 15px; 
  width: 100%; 
  box-sizing: border-box;
  max-width: 350px; 
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}


.login-title {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.login-form, .form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.login-form input[type="email"]
 {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.login-form input[type="email"]:focus
 {
  border-color: #007bff;
  outline: none;
  box-sizing: border-box;
}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.checkbox-label {
  font-size: 14px;
  color: #666;
}

.forgot-password-link {
  font-size: 14px;
  color: #007bff;
  text-decoration: none;
}

.forgot-password-link:hover {
  text-decoration: underline;
}

.login-button {
  width: 100%;
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #0056b3;
}

.signup-link {
  font-size: 14px;
  text-align: center;
  margin-top: 15px;
  color: #666;
}

.signup-link a {
  color: #007bff;
  text-decoration: none;
}

.signup-link a:hover {
  text-decoration: underline;
}

input[type="password"], input[type="text"] {
  width: 100%;
  padding: 10px 40px 10px 10px; 
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2em;
  color: #888;
  transition: color 0.3s ease;
}

.password-toggle-icon:hover {
  color: #007bff;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

.password-input-wraper {
  position: relative; 
  width: 100%; 
  display: flex;
  align-items: center;
}



.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2em;
  color: #888;
}


.error-message.invalid-email {
  color: orange;
}

.error-message.wrong-password {
  color: red;
}

.error-message.user-not-found {
  color: #d9534f;
}

.error-message.network-error {
  color: #f0ad4e;
}


@media (max-width: 768px) {
  .login-box {
    width: 90%; 
    padding: 20px; 
  }

  .login-container {
    min-height: 85vh; 
    padding: 15px;
  }
}

