* {
  margin: 0;
  padding: 0;
}
.sticky-overview {
  top: 0;
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.sticky-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sticky-overview h2 {
  margin-bottom: 10px;
  font-size: 1.8rem;
}

.cruise-price-pick {
  font-size: 1.2rem;
  font-weight: bold;
}
.sticky-layout {
  display: flex;
  margin-top: 0.3rem;
  justify-content: space-between;
}
.date-picker {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cruise-date {
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
}

.datepicker-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px 10px;
  width: fit-content;
}

.date-icon {
  color: #888;
  font-size: 1.2rem;
  cursor: pointer;
}

.custom-datepicker {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 1rem;
  cursor: pointer;
}

.date-displayText {
  display: flex;
  gap: 30px;
  font-size: 1rem;
  color: #555;
}

.date-displayText span {
  font-weight: bold;
}

.cruise-detailss {
  padding: 20px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
.cruise-detailss > h1 {
  background-color: #000e68;
  color: white;
  display: flex;
  margin-bottom: 0.8rem;
  padding: 0.7rem;
  border-radius: 5px;
}
.booking-button {
  background-color: #e97451;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 1.5rem;
  display: flex;
  gap: 0.5rem;
}
.cruise-immages {
  display: flex;
  gap: 10px; /* Space between main image and other images */
}

.cruise-main-image {
  width: 65%; /* Main image takes up most of the width */
  height: auto;
  border-radius: 8px;
  object-fit: cover; /* Ensures the image retains its aspect ratio */
}

.cruise-other-images {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between the smaller images */
  width: 35%; /* Smaller images take up the rest */
}

.cruise-other-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover; /* Ensures smaller images maintain their aspect ratio */
}
.cruise-description {
  font-weight: 600;
  font-size: 1.2rem;
  margin-top: 1.9rem;
}
.price-overview {
  margin: 30px 0;
  width: 100%;
}

.price-overview h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.price-table {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.price-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.price-row:last-child {
  border-bottom: none;
}

.room-type {
  font-weight: bold;
}

.cruise-inclusions {
  margin: 30px 0;
  width: 100%;
}

.cruise-inclusions > h2,
.cruise-inclusions > h3 {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
}
.included-benefits {
  list-style: none;
  padding: 0;
}

.benefit {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 5px 0;
  padding-bottom: 15px;
}

.benefit:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

.benefit-icon {
  color: green;
  font-size: 0.8rem;
  font-weight: 200;
}
.cancel-icon {
  color: red;
  font-size: 0.8rem;
  margin-right: 10px;
}
.view-itinerary-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 15px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.view-itinerary-button:hover {
  background-color: #0056b3;
}

.itinerary-list {
  margin-top: 10px;
  padding-left: 20px;
}

.itinerary-item {
  margin-bottom: 5px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .cruise-immages {
    flex-direction: column; /* Stack images vertically on smaller screens */
  }

  .cruise-main-image,
  .cruise-other-images {
    width: 100%; /* Full width for all images */
  }
  .sticky-text {
    flex-direction: column;
    align-items: flex-start;
  }
}
