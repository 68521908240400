.ship-details {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 0 auto;
}

.ship-detail-image {
  width: 100%;
  height: auto;
  margin: 20px 0;
  border-radius: 8px;
}

.ship-details > h1 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #333;
}

.ship-details > p {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
  font-weight: 800;
}

strong {
  font-weight: bold;
  color: #333;
}

.ship-details > div {
  margin-bottom: 20px;
}
.ship-details div > p:nth-of-type(1) {
  font-weight: 800;
}
.ship-details div > p:nth-of-type(2) {
  line-height: 1.6;
}
.ship-details h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}
