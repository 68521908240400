.newsletter-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 75vh;
  background-image: url("../../images/newsletter-background-image.jpg");
  background-size: cover;
  background-position: center;
  padding: 20px;
}

.newsletter-content {
  position: relative;
  background: #ffffff;
  padding: 30px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.newsletter-content h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 10px;
}

.newsletter-content p {
  color: #666;
  margin-bottom: 20px;
  font-size: 0.95rem;
}

.newsletter-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newsletter-input {
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.subscribe-button {
  background-color: #ff7f50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subscribe-button:hover {
  background-color: #ff6330;
}

.subscription-message {
  margin-top: 10px;
  color: #28a745;
  font-weight: bold;
}
