/* Container Styling */
.company-list-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

/* Partners Section */
.partners-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.partners-section h2 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.all-cruise-lines {
  display: flex;
  align-items: center;
  gap: 10px;
}

.all-cruise-link {
  font-size: 16px;
  color: #0073e6;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #0073e6;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.all-cruise-link:hover {
  background-color: #0073e6;
  color: #fff;
}

.cruise-icon {
  font-size: 20px;
}

/* Company List Styling */
.company-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
}

.company-card {
  text-align: center;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.company-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.company-card img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-bottom: 10px;
}

.company-card h3 {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin: 0;
  word-wrap: break-word;
}
.company-card a {
  text-decoration: none;
}
