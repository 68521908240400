.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modall-content {
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 80%;
  max-width: 500px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 400px) {
  .modall-content {
    width: 83%;
  }
}

.modal-image {
  width: 100%; /* Make the image take the full width */
  max-height: 300px; /* Increase height */
  
  object-fit: cover;
  object-position: center;
  position: relative; /* So the close button can be positioned over it */
}


.closse-button {
  position: absolute;
  top: 58px;
  right: 10px;
  background: red;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.modall-content > p:first-of-type {
  font-weight: 800;
  color: #000e68;
  margin-bottom: 0.4rem;
}
.modall-content > p {
  margin: 1rem;
  line-height: 1.1;
}
.ship-head {
  font-weight: 700;
}
.strikethrough {
  text-decoration: line-through;
  text-decoration-color: red;
}



.book-now-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.book-now-button:hover {
  background-color: #0056b3;
}
