* {
  margin: 0;
  padding: 0;
}
.nav-text {
  text-align: center;
  background-color: #000e68;
  color: white;
  font-weight: bold;
  margin: 0;
  padding: 0.5rem 0;
}

.logo {
  display: flex;
  align-items: center;
}

.logo > img {
  width: 5rem;
  object-fit: contain;
  transform: scale(2.5);
  padding-left: 0.8rem;
}

/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px 20px;
  margin: 0;
}

.nav-links {
  display: flex;
  list-style: none;
}

.nav-links li {
  margin: 0 10px;
  position: relative; /* Added relative positioning for dropdown */
}

.nav-links a {
  color: black;
  text-decoration: none;
  padding: 8px 15px;
  border-radius: 4px;
  display: flex;
  gap: 0.2rem;
}

.nav-links a:hover {
  background-color: #555;
}

/* Dropdown Styles */
.dropdown-menu {
  list-style: none;
  position: absolute;
  top: 100%; /* Positioning the dropdown below the parent item */
  left: -70px;
  background-color: white;
  border: 1px solid #ccc;
  width: 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  z-index: 100; /* Ensure it appears on top */
  display: none; /* Hide the dropdown by default */
}

.dropdown-menu li {
  padding: 8px 15px;
}

.dropdown-menu li a {
  color: black;
  text-decoration: none;
}

.dropdown-menu li a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown when isDropdownOpen is true */
.dropdown.open .dropdown-menu {
  display: block; /* Show the dropdown when it's open */
}

.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 5px;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
    width: 50%;
    flex-direction: column;
    position: absolute;
    top: 90px;
    left: 165px;
    background-color: #000e68;
    align-items: center;
    border-radius: 8px;
    padding: 0.8rem 0;
    z-index: 100;
  }
  .nav-links a {
    color: white;
  }
  .nav-links.active {
    display: flex;
  }

  .hamburger {
    display: block;
  }
  .hamburger.cancel .line {
    background-color: red; /* Example color for the cancel state */
    transform-origin: center;
  }

  .hamburger.cancel .line:nth-child(1) {
    transform: rotate(45deg);
    position: relative;
    top: 6px;
  }

  .hamburger.cancel .line:nth-child(2) {
    opacity: 0;
  }

  .hamburger.cancel .line:nth-child(3) {
    transform: rotate(-45deg);
    position: relative;
    bottom: 6px;
  }
  .nav-text p {
    font-size: 0.7rem;
  }
}
