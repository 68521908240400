footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: #8deefd;
  color: #f9f9f9;
  left: 0;
  bottom: 0;
  right: 0;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.logoo {
  display: flex;
  justify-content: flex-start;
}

.links {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 40px 0 50px;
  width: 100%;
  max-width: 1200px;
}

.links h2 {
  color: rgba(255, 255, 255, 0.6);
}

.links ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  opacity: 0.8;
}

.links ul li a {
  text-decoration: none;
  color: black;
}

.fa-twitter {
  color: black;
}

.footer-secure,
.footer-payments {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

.footer-payments img,
.footer-secure img {
  height: 70px;
}

.logo {
  grid-area: logo;
}

.socials {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 12px;
  flex-wrap: wrap;
  background-color: #000e68;
  border-radius: 8px;
  padding: 0.4rem;
}
.socials a {
  text-decoration: none;
  font-size: 1rem;
  display: inline-block;
  transition: color 0.3s ease;
}

.twitter-icon {
  color: white;
}
i.fab.fa-twitter {
  color: white !important;
}
.instagram-icon {
  color: white;
}

.facebook-icon {
  color: white;
}

.gmail-icon {
  color: white;
}

.socials a:hover {
  color: #555;
}

.links h2 {
  color: #000e68;
}

.links ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  opacity: 0.8;
}
.fa-twitter {
  color: black;
}

.links ul li a {
  text-decoration: none;
  color: black;
}

.footer-payments img {
  height: auto;
  width: auto;
  max-height: 70px;
  max-width: 100%;
  object-fit: contain;
}

.footer-payments {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px 10px;
  position: relative;
  border-bottom: 1px solid #000e68;
  border-top: 1px solid #000e68;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
}
.copyright-text {
  color: #000e68;
  margin-top: 0.5rem;
}
