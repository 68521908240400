.cruise-container {
  padding: 20px;
  background-color: white;
}

.cruise-section {
  margin-bottom: 20px;
}

.cruise-section-title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.cruise-section-text {
  font-size: 1em;
  line-height: 1.6;
  color: #333;
}

.cruise-advisor-links {
  display: flex;
  gap: 1.5rem;
}

.cruise-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.cruise-link:hover {
  text-decoration: underline;
}

.cruise-link-icon {
  margin-left: 5px;
}
