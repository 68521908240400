* {
  margin: 0;
  padding: 0;
}
.company-ship-list {
  padding: 0px 20px;
}
.upper-text {
  padding: 0.3rem 0;
  margin: 0;
  background: #000e68;
  color: white;
  text-align: center;
}
.company-ship-list > .company-list {
  margin-bottom: 20px;
}

.company-logo-navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.company-logo-link {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.logo-with-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.logo-with-name img {
  max-width: 80px;
  transition: transform 0.3s ease;
}

.logo-with-name img:hover {
  transform: scale(1.1);
}

.logo-with-name span {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-top: 5px;
}

.ship-tables {
  margin-top: 40px;
}
/* Company Section */
.company-section {
  margin-bottom: 50px;
  border-bottom: 2px solid #ccc;
  padding-bottom: 30px;
}

.company-header {
  margin-bottom: 20px;
}

.company-logo {
  max-width: 120px;
  margin-bottom: 10px;
}

/* Ship Table */
/* Ship Table */
.ship-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.ship-table th,
.ship-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
  cursor: pointer; /* Makes the entire row clickable */
}

.ship-table th {
  background-color: #f4f4f4;
}

.ship-table td {
  position: relative; /* To allow child elements (like the link) to be styled correctly */
}

.ship-table td:hover {
  background-color: #f5f5f5; /* Light background change on row hover */
}

/* Ship Name Link Styling */
.ship-name-link {
  text-decoration: none; /* Remove underline by default */
  color: inherit; /* Inherit color from parent */
}

.ship-name-link:hover {
  text-decoration: underline; /* Add underline on hover */
  color: #0073e6; /* Change color on hover for better visibility */
}

.ship-image {
  max-width: 90px;
  margin-right: 10px;
  vertical-align: middle;
  height: 60px;
}
.home-icon {
  margin: 0.5rem 0;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  padding: 0.2rem 0.5rem;
}
.home-icon a {
  color: black;
}
/* General Layout for Mobile Screens */
/* Mobile-Friendly Styling */
@media (max-width: 545px) {
  .company-ship-list {
    padding: 0px 10px; /* Reduce padding for mobile */
  }

  .cruise-line {
    display: none;
  }
  .image-text {
    display: flex !important;
    flex-direction: column;
  }
}
