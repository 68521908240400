/* General Dashboard Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.dashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #003366;
  color: white;
  padding: 10px;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.desktop-dashboard-nav{
  display: flex;
  align-items: center;
}

.user-div {
    display: flex
;
    align-items: center;
}

.dashboard-nav a {
  color: white;
  text-decoration: none;
  margin-right: 20px;
}

.dashboard-nav a:hover {
  text-decoration: underline;
}

.user-menu {
  display: flex;
  align-items: center;
  position: relative;
}

.notification-icon {
  margin-right: 10px;
  cursor: pointer;
  font-size: 1.3rem;
}

.user-icon,
.username {
  cursor: pointer;
  margin-right: 4px;
  font-size: 1.3rem;
}

.dropdown-button {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
}

.dropdown-button:hover{
  background-color: white;
  color: #0056b3;
}

.dropdown-menu {
  position: absolute;
  top: 64px;
  left: -89px;
  right: 0;
  background-color: white;
  color: black;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  list-style: none;
  padding: 10px 0;
  z-index: 10;
  display: none; /* Hidden by default */
}

.user-menu .dropdown-menu {
  display: block; /* Make visible when state changes */
}

.dropdown-menu li {
  padding: 10px 20px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.dashboard-main {
  flex: 1;
  padding: 20px;
}

.widget-display {
  margin-top: 20px;
  font-size: 18px;
}


/* Notifications Modal Styles */
.notifications-modal {
  position: absolute;
  top: 60px;
  right: 20px;
  width: 300px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 20;
}

.notifications-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #003366;
  color: white;
  border-bottom: 1px solid #ddd;
}

.notifications-header h3 {
  margin: 0;
  font-size: 16px;
}

.notifications-header button {
  background-color: white;
  color: #003366;
  border: none;
  font-size: 14px;
  cursor: pointer;
}

.notifications-header button:hover {
  text-decoration: underline;
}

.notifications-list {
  padding: 10px;
  margin: 0;
  color: black;
}

.notifications-list li {
  list-style: circle;
 margin: 5px 10px
}

.notifications-list li:last-child {
  border-bottom: none;
}

.notification-count {
  background: red;
  color: white;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 50%;
  margin-left: 5px;
  vertical-align: top;
}

.notification-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: background 0.3s;
}

.notification-item.unread {
  background-color: #f8d7da; /* Light red for unread */
  font-weight: bold;
}

.notification-item.read {
  background-color: #d4edda; /* Light green for read */
  font-weight: normal;
}



.top-sectionn {
    display: flex
;
    align-items: center;
    justify-content: space-between;
}

.top-section > div {
    display: flex
;
    gap: 0.4rem;
        align-items: center;
}

select.sort-dropdown {
    padding: 10px;
}

.sort-dropdown-wrapper {
  position: relative;
  display: inline-block;
}

.sort-dropdown {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
  font-size: 14px;
  background-color: #fff;
}


.sort-label {
    border-left: 2px solid grey;
    padding: 3px;
}




/* Cruise Details Styles */
.card-section {
  padding: 2rem;
}

/* Card Container */
.card-container {
  display: flex;
  flex-wrap: wrap; /* Ensures cards wrap to the next row if they don't fit */
  gap: 20px; /* Adds spacing between cards */
  justify-content: center; /* Center-align cards in the container */
  padding: 20px; /* Adds padding around the container */
  box-sizing: border-box; /* Includes padding in width calculations */
}

/* Card */
.card {
  flex: 0 0 100%; /* Default: Cards take full width (for small screens) */
  max-width: 100%; /* Prevents cards from exceeding full width */
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

/* Card Image */
.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

/* Card Content */
.card-content {
  padding: 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
}

.card-content h3 {
  font-size: 1.2rem;
  margin: 0;
  text-transform: capitalize;
}

.card-content p {
  font-size: 0.95rem;
  color: #666;
  margin: 0;
  text-transform: capitalize;
  display: flex;
  gap: 8px; /* Add spacing between the icon and text */
}

.icon {
  font-size: 1rem; /* Adjust icon size */
  color: #0d6efd; /* Match the color of the icon */
}

/* Card Footer */
.card-footer {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-footer span {
  font-size: 1rem;
  color: #333;
}

.info-button {
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.info-button:hover {
  background-color: #0056b3;
}

/* Responsive Media Queries */
@media (min-width: 576px) {
  .card {
    flex: 0 0 100%; /* Cards take full width on extra-small to small screens */
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .card {
    flex: 0 0 50%; /* Cards take half the width on medium screens */
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .card {
    flex: 0 0 33.333%; /* Cards take one-third of the width on large screens */
    max-width: 33.333%;
  }
}

@media (min-width: 1200px) {
  .card {
    flex: 0 0 25%; /* Cards take one-fourth of the width on extra-large screens */
    max-width: 25%;
  }
}

/* pagination */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-button {
  margin: 0 5px;
  padding: 10px 15px;
  border: 1px solid #ccc;
  background-color: transparent;
  color: black;
  cursor: pointer;
  transition: background-color 0.3s;
}

.page-button:hover {
  background-color: #f0f0f0;
}

.page-button.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
  cursor: default;
}

.showing-text {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  color: #666;
}

@media (max-width: 768px) {
  .top-sectionn {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    margin-bottom: 1.2rem;
  }

  .card-container, .dashboard-main{
    padding: 0;
  }

  .dropdown-icon {
    right: 105px;
    top: 24.5%;
  }

  
}

/* Mobile Menu Button */
.mobile-menu-button {
  display: none; /* Hidden by default */
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Mobile Menu Modal */
.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 70%;
  height: 100vh;
  background: rgba(18, 38, 74, 0.95);
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.mobile-menu .dashboard-nav {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.mobile-menu .dashboard-nav a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 10px;
}

.mobile-menu .user-menu {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
}

/* Hide Desktop Nav & Show Mobile Menu Button on Small Screens */
@media (max-width: 768px) {
  .dashboard-nav,
  .user-menu {
    display: none; /* Hide desktop navigation */
  }

  .mobile-menu-button {
    display: block; /* Show menu button */
  }

  .user-div{
    display: flex;
            gap: 5px;
  }

  .notification-icon, .user-icon{
    margin: 0;
  }

  .dropdown-menu{
    top: 80px;
    left: 15px;
  }

  .bacck-button {
  display: flex;
  gap: 5px;
  padding: 10px;
  background: none;
  border: none;
  color: yellow;
  font-size: 16px;
  cursor: pointer;
}

.bacck-button:hover {
  color: #0056b3;
}
}

.notification-item.unread {
  background-color: #f8d7da; /* Light red */
  color: #721c24;
}

.notification-item.read {
  background-color: #d4edda; /* Light green */
  color: #155724;
}


