.recommendation-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}
.recommendation-page {
  padding: 20px;
}
.recommendation-page h2 {
  text-align: center;
  margin-bottom: 0.4rem;
  text-decoration: underline;
}
.recommendation-card {
  border: 1px solid #ddd;
  border-radius: 8px;
}

.recommendation-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.recommendation-details {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.recommendation-price-row {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.info-button {
  background: none;
  border: none;
  color: #007bff; /* Adjust the color */
  font-size: 1.5rem;
  cursor: pointer;
}

.info-button:hover {
  color: #0056b3; /* Adjust hover color */
}
